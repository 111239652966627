import { inject, Injectable, Injector } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { FlatfileListener } from '@flatfile/listener';
import api, { Flatfile } from '@flatfile/api';
import { Observable, Subject } from 'rxjs';
import { ConfigService } from '@core/services/config.service';
import { UserService } from '@core/services/user.service';
import { HttpService } from '@core/services/http.service';
import {
  FLATFILE_WRAPPER_DATA_INJECTION_TOKEN,
  FlatfileSpaceConfig,
  FlatfileSpaceWrapperComponent,
} from '@core/services/flatfile/flatfile-space-wrapper/flatfile-space-wrapper.component';

export interface ProductUploadCompletedEvent {
  jobId: string;
  workbookId: string;
}

type ResultSubjectPayload = ProductUploadCompletedEvent | undefined;

interface ImportProductsResponse {
  userJobId: number;
}

@Injectable()
export class FlatfileService {
  private overlayRef: OverlayRef | undefined;
  private resultSubject: Subject<ResultSubjectPayload> | undefined;

  private readonly overlay = inject(Overlay);
  private readonly userService = inject(UserService);
  private readonly httpService = inject(HttpService);

  uploadFile(workbook: Flatfile.CreateWorkbookConfig): Subject<ResultSubjectPayload> {
    this.closeImporterPortal();
    this.resultSubject = new Subject<ResultSubjectPayload>();

    const userId = this.userService.userProfile.id;
    const companyId = this.userService.userCompany.id;

    const config: FlatfileSpaceConfig = {
      environmentId: ConfigService.settings.flatfileEnvironmentId,
      publishableKey: ConfigService.settings.flatfilePublishableKey,
      workbook,
      listener: this.createListener(this.resultSubject),
      closeSpace: {
        onClose: this.createSpaceCloseHandler(this.resultSubject),
      },
      userInfo: {
        userId: userId.toString(),
        companyId: companyId.toString(),
      },
    };

    this.openImporterPortal(config);
    return this.resultSubject;
  }

  importProducts(
    jobId: string,
    workbookId: string,
    worklistName: string | undefined,
  ): Observable<ImportProductsResponse> {
    return this.httpService.postV2<ImportProductsResponse>('/api/companies/my/products/fileImport/flatfile', {
      flatFileWorkbookId: workbookId,
      flatFileJobId: jobId,
      worklistName,
      shouldSaveToWorklist: !!worklistName,
    });
  }

  private openImporterPortal(spaceConfig: FlatfileSpaceConfig): void {
    const portal = new ComponentPortal(
      FlatfileSpaceWrapperComponent,
      null,
      Injector.create({
        providers: [{ provide: FLATFILE_WRAPPER_DATA_INJECTION_TOKEN, useValue: spaceConfig }],
      }),
    );

    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      panelClass: ['clover-priority-overlay-panel'],
      backdropClass: ['clover-modal-backdrop', 'clover-priority-overlay-backdrop'],
      positionStrategy: this.overlay.position().global().centerVertically().centerHorizontally(),
    });

    this.overlayRef.attach(portal);
  }

  private closeImporterPortal(): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef.dispose();
    }

    if (this.resultSubject) {
      this.resultSubject.next(undefined);
      this.resultSubject.complete();
    }
  }

  private createListener(resultSubject: Subject<ResultSubjectPayload>): FlatfileListener {
    return FlatfileListener.create((listener) => {
      listener.filter({ job: 'workbook:importAction' }, (configure) => {
        configure.on('job:ready', async (event) => {
          const { jobId, workbookId } = event.context;

          try {
            await api.jobs.complete(jobId);
            resultSubject.next({ jobId, workbookId });
          } catch (error: unknown) {
            await api.jobs.fail(jobId);
            resultSubject.error(error);
          }

          resultSubject.complete();
          this.closeImporterPortal();
        });
      });
    });
  }

  private createSpaceCloseHandler(resultSubject: Subject<ResultSubjectPayload>): () => void {
    return () => {
      resultSubject.next(undefined);
      resultSubject.complete();

      this.closeImporterPortal();
    };
  }
}
